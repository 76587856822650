import React from "react";
import { Link } from "gatsby";

const Calltoaction = (props) =>{
    return(
        <>
        <div className={props.sid}>
            <div className="container">
                <div className="contactus-area">
                    <div className="left-cont">
                        <h2 className="heading-cb">Need help with your eCommerce store</h2>
                        <p>Please <Link to="/contact/" className="a-simple">share </Link>  your requirements in detail</p>    
                    </div>
                    <div className="right-cont">
                        <Link to="/contact/" className="contactus-btn">Contact us</Link>
                    </div>
                </div>    
            </div>
        </div>
        </>
    )
}
export default Calltoaction;